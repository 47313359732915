                        /* basic styles */

/* box-sizing for all elements in html */
html, * {
  box-sizing: border-box !important;
}
*::before,
*::after {
  box-sizing: inherit !important;
}

/* body */
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Fira Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

a {
  text-decoration: none !important;
}
